/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

import { Layout, SEO } from "../components"
import { Container } from "../components"

const ThankYouPage = () => (
  <Layout>
    <SEO title="Thank You" />
    <Container>
      <Styled.h1 sx={{ textAlign: "center" }}>
        Thank you!
        <br />
        We’ll be in touch soon.
      </Styled.h1>
    </Container>
  </Layout>
)

export default ThankYouPage
